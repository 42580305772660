/**
 * The `reportWebVitals` function is used to report web vitals metrics to a provided callback function.
 * @param {ReportHandler} [onPerfEntry] - The `onPerfEntry` parameter is a callback function that will
 * be called with performance metric data. It is an optional parameter, so it can be undefined.
 */
import { ReportHandler } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
