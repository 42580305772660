/* This code is responsible for rendering the React application to the DOM. */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

const LazyLoader: LazyLoaderType<any> = (Component) => (props) => (
  <React.Suspense fallback={null}>
    <Component {...props} />
  </React.Suspense>
);

const App = LazyLoader(React.lazy(() => import('./App')));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
